import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
    reviewsWidth: {
        width: '60%',
        margin: '0 auto',
        '@media screen and (max-width: 568px)': {
            width: '100%',
            margin: '0,'
        },
    },
    margin: {
        margin: '0 10px'
    },
    reportsWidth : {
        width: '90%',
        margin: '0 auto',
        '@media screen and (max-width: 568px)': {
            width: '100%',
            margin: '0,'
        },
    },
    ratecardHolder : {
        position: 'relative',
        minHeight: '600px',
        overflowX: 'scroll ! important'
    }
}));
