import { Typography } from '@mui/material';
import ReactPlayer from 'react-player'
import FsLightBox from '@guidesly/fslightbox-react-v2'
import React, { useState, useEffect } from 'react';
import StackGrid, { transitions, easings } from 'react-stack-grid';
import { get } from '../../widgets/utils/axiosHelper'
import useStyles from './styles'
import './styles.scss'

const transition = transitions.scaleDown;

const mediaTypes = [
    'videos',
    'ratecard',
    'images',
    'Fishing Catch',
    'Fishing Spot',
    'Trip Ratings',
    'Fishing Trip',
    'Fishing Report'
]

const PhotoGallery = (props) => {
    const classes = useStyles()
    const [gallery, setGallery] = useState([])
    const [open, setOpen] = useState(false)
    const [photoIndex, setPhotoIndex] = useState()

    useEffect(() => {
        const url = `guide/details?id=${props.id}&widget_gallery=true`;
        get(url)
            .then((res = {}) => {
                const { status, data: { media = [] } = {} } = res
                if (status === 200 && media.length > 0) {
                    const galleries = media.filter(item => mediaTypes.includes(item.media_type) && item.show_on_lcal)
                    setGallery(galleries)
                }
            }).catch((e) => {
                console.log('API Error: reviews', e)
            });
    }, []);

    const isNarrow = /Mobi|Android|iPad|iPhone|iPod/i.test(navigator.userAgent) || window.innerWidth <= 834 || ((screen || {}).width || 0) <= 834
    const sources = gallery.map(image => `${cloudfront}${image.mediapath}`)
    const types = gallery.map(image => image.media_type === "videos" ? 'video' : 'image')
    const captions = gallery.map(image => image.caption)

    return (
        <>

            <StackGrid
               monitorImagesLoaded
               columnWidth={isNarrow ? 150 : 180}
               duration={600}
               gutterWidth={isNarrow ? 20 : 26}
               gutterHeight={isNarrow ? 10 : 20}
               easing={easings.cubicOut}
               appearDelay={60}
               appear={transition.appear}
               appeared={transition.appeared}
               enter={transition.enter}
               entered={transition.entered}
               leaved={transition.leaved}
            >
                {gallery.map((image, index) => <div key={image.mediaid} onClick={() => {
                    setPhotoIndex(index)
                    setOpen(true)
                }}>
                    {image.media_type !== 'videos' ?
                        <figure
                            className={classes.container}
                        >
                            <img className={classes.img} onError={() => setGallery(images => images.filter(item => item.mediaid !== image.mediaid))} src={`${cloudfront}${image.mediapath}`} alt={image.medianame} />
                            <figcaption>
                                <Typography className={classes.caption} variant="subtitle2" >
                                    {image.caption}
                                </Typography>
                            </figcaption>
                        </figure> :
                        <>
                            <video className={classes.video}
                                width="100%"
                                height="240px">
                                <source src={`${cloudfront}${image.mediapath}`}></source>
                            </video>
                            <Typography className={classes.caption} variant="subtitle2" >
                                {image.caption}
                            </Typography>
                        </>
                    }
                </div>)}
            </StackGrid>
            {open && <FsLightBox
                toggler={open}
                types={types}
                sources={sources}
                captions={captions}
                slide={photoIndex + 1}
                disableLocalStorage
                onClose={() => setOpen(false)}
                openOnMount
            />}
        </>
    );
}

export default PhotoGallery;
