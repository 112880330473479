import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import ReactDayPicker from 'react-day-picker';
import CircularProgress from '@mui/material/CircularProgress';
import './styles.scss';
import getSymbolFromCurrency from 'currency-symbol-map'
import Tooltip from '@mui/material/Tooltip';
const InfoIcon = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/black_info.png'
import moment from 'moment';
import GreenRadio from '../../../common/Radio';
import convertTo12Hour, { getDuration, getDurationAndPrice, getTimeFormat } from '../../../utils/time';
import { displayMoney } from '../../../utils/payment';
import useStyes from './styles';
import { TYPES } from '../../../common/description'
import { getAvailabilityBasedOnSelectedRateCard, getRateCards, handleDateSelect } from '../../../utils/guide-details';
import { styled } from '@mui/system';
import GuestCount from '../../../common/filters/GuestCount';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TripCharge from '../../../common/TripCharge';
import RateCardsSelect from '../../../live-calendar-level-1/components/rate-cards/rate-cards-select';

const additionalChargeIcon = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/images/marlin/24hrs-01.svg';
const boatIcon = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/images/marlin/private-boat-01-01.svg';
const experienceIcon = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/images/marlin/pin_waterbodies-01.svg';
const targetedFishIcon = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/images/marlin/target-fish-01.svg';
const locationPin = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/rcLoc.svg'
const huntingIcon = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/scripts/hunting.png';
const checked = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/checked.svg';
const unchecked = 'https://guidesly-assets.s3.us-east-2.amazonaws.com/web/static/images/uncheck.svg';

export const getVehicleDetails = (allVehicles, data) => {
  if (allVehicles && data?.vehicles?.length > 0) {
    return (
      <p>
        Vehicles:  <span style={{ color: "black", fontWeight: 500 }}> {data?.length && data?.length != "" ? `${data.length}'` : null}
          {data?.manufacturer_name != "" && data?.manufacturer_name != null  && data?.manufacturer_name != "Others"  && data?.manufacturer_name != "Other"  ? `${data.manufacturer_name}` : data?.other_manufacturer?data?.other_manufacturer: null}{data?.name != "" && data?.name != null ? `, ${data?.name}` : null}
          <span className={'AddDot'}>{data?.maximum && data?.maximum === 1 ? ` Max ${data.maximum} guest (base trip price includes ${data.maximum} guest)` : data?.maximum && data?.maximum > 1 ? ` Max ${data.maximum} guests (base trip price includes ${data.maximum} guests)` : null} </span></span>
      </p>
    )
  }
}


const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className, ...props.classes }} />
))(({ theme }) => ({
  'tooltip': {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const RateCards = ({
  rateCards,
  selectedHour,
  handleClick,
  selectedRateCard,
  instantBook,
  selectedDay,
  guestCount,
  setShowCalendar,
  modifiers,
  setModifiers,
  setGuestCountHandler,
  disabled,
  capacity,
  fromMonth,
  toMonth,
  month,
  setSelectedRateCard,
  setSelectedHour,
  setSelectedDay,
  setRateCards,
  modifiersStyles,
  showCalendar,
  availableSchedules,
  stateRateCards,
  setStep,
  setTripCalculator,
  setExpandedProp,
  isMultiTenantGuide,
  companyGuid,
  availableSpecies,
  setReferralGuideName,
  referral,
  schrecords,
  setSource,
  userData
}) => {
  const classes = useStyes();
  const [initialDisabled, setInitialDisabled] = useState(false)
  const [readMoreRateCard, setReadMoreRateCard] = useState([]);
  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    if (guestCount > 1) {
      setInitialDisabled(true)
    }
  }, [guestCount])

  const getReferralName = (rateCard) => {
    if(rateCard?.is_group) {
    return (referral?.filter(x => x.group_guid == rateCard.guideguid))?.[0]?.company;
    } else {
      return (referral?.filter(x => x.guideguid == rateCard.guideguid))?.[0]?.guidename;
    }
  
}
const getReferralTitle = (rateCard) => {
    if(rateCard?.is_group) {
    return (referral?.filter(x => x.group_guid == rateCard.guideguid))?.[0]?.title
    } else {
      return (referral?.filter(x => x.guideguid == rateCard.guideguid))?.[0]?.title
    }
}

  const handleDayClick = (e, dayPickerModifiers = {}) => {
    e.setHours(0, 0, 0, 0);
    setSelectedRateCard({});
    setSelectedHour({});

    if (dayPickerModifiers.availability) {
      setSelectedDay(e);
      const selectedSchedule = availableSchedules.find(
        (schedule) =>
          schedule.date === moment(e).format('YYYY-MM-DD'),
      );
      const rateCards1 = getRateCards(JSON.parse(JSON.stringify(stateRateCards)), selectedSchedule)
      setRateCards(
        rateCards1.filter(item => guestCount <= item.maximum && item.rates)
      );
      if (showCalendar) {
        const { rateCard, selectedHour, hourcard } = showCalendar;
        if (hourcard) {
          setSelectedRateCard(rateCard)
          setSelectedHour(selectedHour)
          setShowCalendar(null)
        } else {
          setSelectedRateCard(rateCard)
          if (selectedHour) {
            setSelectedHour(selectedHour)
          }
        }
        const completeRateCard = rateCards.find(rate => rate.id === rateCard.id)
        if (!completeRateCard.disabled && !completeRateCard.rates[0].disabled) {
          instantBook(rateCard, selectedHour)
        }
        if (rateCards.length === 1) {
          setSelectedRateCard(rateCards[0])
        }
      }
      setExpanded('triptype')
    }
    if (
      dayPickerModifiers.scheduleDisable ||
      dayPickerModifiers.unavailablity
    ) {
      return null;
    }
    return null;
  };

  const list = selectedDay ? rateCards.filter(item => !item.disabled) : rateCards;
  const getBoatDetail = (rateCard) => {
    if (rateCard) {
      return (
        <div>
          <img
            className={classes.icon}
            src={boatIcon}
          />
          <span style={{ textTransform: "capitalize", }}>{rateCard?.type == "atv/utv"? "ATV/UTV":rateCard?.type}:</span>{' '}
          <span className={classes.bold}>
            {rateCard.length}{rateCard.length?"' ":null}{(rateCard.manufacturer_name !="Other" && rateCard.manufacturer_name !="Others")?rateCard.manufacturer_name: rateCard.other_manufacturer}{'-'}
            Max {rateCard.maximum} guests
          </span>
        </div>
      )
    }
  }
  const handleSelect = (item, rateCard) => {
    handleClick(item, rateCard)
    const allSch = schrecords?.schedule
    const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({ "availableSchedules": allSch, hourCard: item, })
    setModifiers({
      ...modifiers,
      availability,
      scheduleDisable,
    });
    setSource("mobile")
    setTripCalculator(true)
    setStep('personal-details')
    setSelectedDay('')
  }
  const checkCompany = isMultiTenantGuide && companyGuid && companyGuid != ""
  return (
    <Grid container>
      <Grid item sm={12} xs={12} md={8} lg={8}>
        {list.length ? rateCards.map((rateCard, index) => {
          const allDisabled = rateCard.rates.every(item => item.disabled)
          return (
            <Grid container className={classes.rateCardContainer} style={{ marginTop: 10, marginBottom: 10, borderRadius: 5 }}>
              <Grid style={{ padding: 15 }} item xs={12} sm={12} md={6} lg={6}>
                <CardMedia
                  className={classes.media}
                  image={`${cloudfront}${rateCard.coverpicture}`} // eslint-disable-line no-undef
                />
                <Typography
                  variant="body2"
                  component="div"
                  className={classes.boldtitle}
                >
                  {rateCard.title} {rateCard.activity_type === 'fishing' && (rateCard.activity_type === 'cruises'? '':rateCard.ratetype)}
                  {rateCard.activity_type === 'fishing' && <span style={{ position: 'relative', top: 7 }}>
                    <HtmlTooltip title={TYPES[rateCard.ratetype.toLowerCase()]}>
                      <span><img src={InfoIcon} width={20} height={20} /></span>
                    </HtmlTooltip>
                  </span>}
                </Typography>
                {rateCard.is_referral ?
                  <Typography variant="body2" className={classes.referral}>
                    <div>{`Affiliated ${getReferralTitle(rateCard)} ${getReferralName(rateCard)}`}</div>
                  </Typography>
                  : null}
                {rateCard.has_boat && (
                  <Typography
                    variant="body2"
                    component="div"
                    className={classes.subtitle1}
                  >
                    <div style={{ textTransform: "capitalize", }}>
                      <img
                        className={classes.icon}
                        src={boatIcon}
                      />
                      {rateCard.is_shared
                        ? `Shared ${rateCard?.type == "atv/utv"? "ATV/UTV":rateCard?.type} Experience`
                        : `Private ${rateCard?.type == "atv/utv"? "ATV/UTV":rateCard?.type} Experience`}
                    </div>
                  </Typography>
                )}

              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6} >
                <Accordion style={{ width: '100%', margin: 0 }} expanded={expanded === rateCard.id} onChange={handleChange(rateCard.id)}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    classes={{
                      content: classes.block
                    }}
                    style={{ border: expanded === 'triptime' ? '2px solid #086edc' : 'none' }}
                  >
                    <Typography className={classes.secondaryHeading}>Trip Details</Typography>
                  </AccordionSummary>
                  <AccordionDetails style={{ display: 'block !important' }}>
                    <div>
                      {rateCard.activity_type === 'hunting' ?
                        <Typography variant="body2" style={{ marginBottom: 10 }}>
                          <div>{`Hunting - ${rateCard.ratetype}`}</div>
                        </Typography>
                        : rateCard.activity_type === 'tours' || rateCard.activity_type === 'snow_sports'|| rateCard.activity_type === 'water_sports' ||rateCard.activity_type === 'horseback_riding' ||rateCard.activity_type === 'cruises' || (rateCard.activity_type === 'off_roading' ||rateCard.activity_type === 'offroading') ?
                          <Typography variant="body2" style={{ marginBottom: 10 }}>
                            <div>{`${ (rateCard.activity_type === 'cruises'? '':rateCard.ratetype)}`}</div>
                          </Typography>
                          : null
                      }

                      <Typography variant="body2" style={{ marginBottom: 10, textTransform: 'capitalize' }}>
                        {rateCard.activity_type === 'hunting' ? `${rateCard.land_type} Land Hunt Experience` : rateCard.activity_type === "tours" ? `Tour Experience` :rateCard.activity_type === "snow_sports" ? `Snow Sports Experience` :rateCard.activity_type === "horseback_riding" ? `Horseback Riding Experience` :rateCard?.activity_type === "water_sports" ? `Water Sports Experience` : (rateCard.activity_type === 'off_roading' ||rateCard.activity_type === 'offroading') ? `Off-roading Experience` :rateCard.activity_type === "cruises" ? `Cruises Experience` : `Fishing Experience`}
                      </Typography>
                      {rateCard.activity_type === 'hunting' || rateCard.activity_type === 'snow_sports'||rateCard.activity_type === 'horseback_riding'|| rateCard.activity_type === 'water_sports' ||rateCard.activity_type === 'cruises' || (rateCard.activity_type === 'off_roading' ||rateCard.activity_type === 'offroading') && <Typography variant="body2" style={{ marginBottom: 10, textTransform: 'capitalize' }}>
                        {getVehicleDetails([], rateCard)}
                      </Typography>}
                      {rateCard.has_boat ? (
                        <Typography
                          variant="body2"
                          component="div"
                          className={classes.subtitle1}
                        >

                          {!isMultiTenantGuide &&
                            getBoatDetail(rateCard)
                          }
                          {checkCompany && rateCard.boats &&
                            rateCard.boats.length === 1 &&
                            getBoatDetail(rateCard)
                          }
                          {checkCompany && rateCard.boats &&
                            rateCard.boats.length > 1 &&
                            <div>
                              <img
                                className={classes.icon}
                                src={boatIcon}
                              />
                              <span className={classes.bold}>
                                {rateCard.boats.length} boats available
                              </span>
                            </div>
                          }
                        </Typography>
                      ) :
                        (
                          <Typography
                            variant="body2"
                            component="div"
                            className={classes.subtitle1}
                          >
                            <div>
                              <img
                                className={classes.icon}
                                src={boatIcon}
                              />
                              Capacity:{' '}
                              <span className={classes.bold}>
                                Max {rateCard.maximum} {rateCard.maximum > 1 ? 'guests' : 'guest'}
                                {" (base trip price includes "}
                                {rateCard.guests}
                                {rateCard.guests > 1 ? " guests)" : " guest)"}
                              </span>
                            </div>
                          </Typography>
                        )}
                      {rateCard.is_auto_add_trip_extension > 0 && (
                        <Typography
                          variant="body2"
                          component="div"
                          className={classes.subtitle1}
                        >
                          <img
                            className={classes.icon}
                            src={additionalChargeIcon}
                          />
                          Allows for hourly add-ons
                        </Typography>
                      )}
                      {(["hunting", "tours","snow_sports","cruises","off_roading","offroading","water_sports","horseback_riding"].includes(rateCard.activity_type)) && rateCard.fish_species_ids?.length > 0 && (
                        <Typography
                          variant="h3"
                          className={classes.subtitle4}
                          style={{ fontSize: 14 }}
                        >
                          <img
                            className={classes.icon}
                            src={huntingIcon}
                          />
                          <span >
                            {rateCard.activity_type === "tours"|| rateCard.activity_type === "snow_sports"||rateCard.activity_type === "horseback_riding"|| rateCard.activity_type === 'water_sports' ||rateCard.activity_type === 'cruises' || (rateCard.activity_type === 'off_roading' ||rateCard.activity_type === 'offroading')  ? <b>Target Species:{' '}</b> : <b>Target Animals:{' '}</b>}
                            <span className={classes.bold}>
                              {rateCard.fish_species_ids.map(id => availableSpecies.find(item => item.id === id)?.title?.trim('')).join(', ')}
                            </span>
                          </span>
                        </Typography>
                      )}
                      {rateCard?.poi_guids && rateCard?.poi_guids?.length > 0 &&
                        <Typography
                          variant="h3"
                          className={classes.subtitle4}
                          style={{ fontSize: 14 }}
                        >
                          <img
                            className={classes.icon}
                            src={locationPin}
                          />
                          {rateCard?.poi_guids?.length === 1 ?
                            `${rateCard?.poi_guids?.length} Meeting location available` :
                            `${rateCard?.poi_guids?.length} Meeting locations available`
                          }
                        </Typography>
                      }
                      {rateCard.fish_species && rateCard.activity_type === 'fishing' && (
                        <Typography
                          variant="body2"
                          component="div"
                          className={classes.subtitle1}
                        >
                          <img
                            className={classes.icon}
                            src={targetedFishIcon}
                          />
                          <span className={classes.bold}>
                            Target Fish: <span>
                              {rateCard.fish_species.map(({ title }) => title.trim('')).join(', ')}
                            </span>
                          </span>
                        </Typography>
                      )}
                      {rateCard.description && (
                        <Typography variant="body2" component="div" style={{ paddingTop: 15 }}>
                          {rateCard.description.length <= 180 &&
                            rateCard.description}
                          {rateCard.description.length > 180 && (
                            <>
                              {readMoreRateCard.includes(
                                rateCard.id,
                              ) ? (
                                <>
                                  {rateCard.description}{' '}
                                  <span
                                    className={classes.showMoreLess}
                                    onClick={() => {
                                      const remove = readMoreRateCard.filter(
                                        (item) =>
                                          item !== rateCard.id,
                                      );
                                      setReadMoreRateCard(remove);
                                    }}
                                  >
                                    Show Less
                                  </span>
                                </>
                              ) : (
                                <>
                                  {rateCard.description.substr(0, 179)}...{' '}
                                  <span
                                    className={classes.showMoreLess}
                                    onClick={() => {
                                      setReadMoreRateCard([
                                        ...readMoreRateCard,
                                        rateCard.id,
                                      ]);
                                    }}
                                  >
                                    Show More
                                  </span>
                                </>
                              )}
                            </>
                          )}
                        </Typography>
                      )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid
                style={{ padding: '0 16px' }}
                xs={12}
                md={12}
                className={`${classes.instantBookContainer}`}
              >
                <Grid container>
                  <Grid item md={12} xs={12} className={classes.spacing}>
                    <Typography
                      variant="body2"
                      component="div"
                      className={classes.bold}
                    >
                      Available Trips 
                    </Typography>
                    <Grid style={{ marginTop: 5 }} item>
                      {rateCard.rates
                        .filter((item) => !item.disabled)
                        .map((item) => (
                          <Typography
                            variant="body2"
                            component="div"
                          >
                            <img src={(selectedHour.id === item.id) ? checked : unchecked} onClick={() => {
                              handleSelect(item, rateCard)
                            }} style={{
                              margin: 0,
                              padding: 0,
                              borderRadius: 0,
                              marginRight: 10,
                              minWidth: 20,
                              height: 20, width: 20, border: '1px solid grey'
                            }} width="20" height="20"/>
                            <span
                              onClick={() => {
                                handleSelect(item, rateCard)
                              }}
                              className={`${(item.id === rateCard.rates[0].id &&
                                rateCard.id !==
                                selectedRateCard.id) ||
                                selectedHour.id === item.id
                                ? classes.selected
                                : ''
                                }`}
                              style={{ position: 'relative', bottom: 5 }}
                            >
                              {`${convertTo12Hour(
                                item.start_time,
                              )} ${getDuration(item)
                                } - ${(getSymbolFromCurrency(userData?.currency) || '$')}${displayMoney(item.rate)}`}
                                {rateCard.is_shared_trip ? Boolean(item?.max_occurrence) ? ` - ${item?.max_occurrence}  ${item?.max_occurrence > 1 ? "Seats" : "Seat"}` : '1 Seat' : ''}
                            </span>
                          </Typography>
                        ))}
                      {rateCard.rates
                        .filter((item) => item.disabled)
                        .map((item) => (
                          <Typography
                            variant="body2"
                            component="div"
                          >
                            <img src={(selectedHour.id === item.id) ? checked: unchecked} onClick={() => {
                              handleSelect(item, rateCard)
                            }} style={{
                              borderRadius: 0,
                              margin: 0,
                              padding: 0,
                              marginRight: 10,
                              minWidth: 20,
                              height: 20, width: 20, border: '1px solid grey',
                              cursor: 'pointer'
                            }} width="20" height="20"/>
                            <span
                              onClick={() => {
                                handleSelect(item, rateCard)
                              }}
                              className={`${(item.id === rateCard.rates[0].id &&
                                rateCard.id !==
                                selectedRateCard.id) ||
                                selectedHour.id === item.id
                                ? classes.selected
                                : ''
                                }`}
                              style={{ position: 'relative', bottom: 5 }}
                            >
                              {`${convertTo12Hour(
                                item.start_time,
                              )} ${getDuration(item)
                                } - ${(getSymbolFromCurrency(userData?.currency) || '$')}${displayMoney(item.rate)}`}
                                {rateCard.is_shared_trip ? Boolean(item?.max_occurrence) ? ` - ${item?.max_occurrence}  ${item?.max_occurrence > 1 ? "Seats" : "Seat"}` : '1 Seat' : ''}
                            </span>
                          </Typography>
                        ))}
                    </Grid>
                  </Grid>
                  <Grid item md={12} xs={12} className={classes.spacing1}>
                    <button
                      onClick={() => {
                        const allSch = schrecords?.schedule
                        const { availability, scheduleDisable } = getAvailabilityBasedOnSelectedRateCard({
                          "availableSchedules": allSch, hourCard: selectedHour &&
                            selectedHour.guide_ratecard_id === Number(rateCard.id) ? selectedHour : rateCard.rates[0], rateCard
                        })
                        setModifiers({
                          ...modifiers,
                          availability,
                          scheduleDisable,
                        });
                        setSelectedRateCard(rateCard)
                        if (selectedDay) {
                          if (availability.find(item => new Date(item).getTime() === new Date(selectedDay).getTime())) {
                            setStep('personal-details')
                          } else {
                            setStep('personal-details')
                            setSelectedDay('')
                          }
                        }
                        setSource("mobile")
                        setTripCalculator(true)
                        setStep('personal-details')
                      }}
                      className={'bookNowBtn'}
                      style={{ width: '100%', borderRadius: 5, height: 40, marginLeft: 0, marginBottom: 10 }}
                    >
                      {`Starting at ${(getSymbolFromCurrency(userData?.currency) || '$')}
                      ${selectedHour.rate &&
                        selectedRateCard.id === rateCard.id
                        ? displayMoney(selectedHour.rate)
                        : displayMoney(rateCard.rates[0].rate)}`}
                    </button>

                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        }) : <div style={{ margin: '0 auto', width: 30 }}>
          <CircularProgress size={30} color="inherit" />
        </div>}
      </Grid>
    </Grid>
  );
};

export default RateCards;
